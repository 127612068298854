// eslint-disable-next-line import/no-named-as-default
import { createInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { i18n as glideGridI18n } from 'glidegrid';

const localePath = process.env.REACT_APP_CYPRESS_BASE_URL
  ? `static/build/locales/{{lng}}/translation.json`
  : 'locales/{{lng}}/translation.json';

const getTQHostUrl = () => {
  if (process.env.NODE_ENV === 'production' && !process.env.REACT_APP_CYPRESS_BASE_URL) {
    return `https://%TQ_HOST_URL%`;
  }

  if (process.env.REACT_APP_CYPRESS_BASE_URL) {
    return process.env.REACT_APP_CYPRESS_BASE_URL;
  }

  return 'http://localhost:3003';
};

const tqI18N = createInstance();
// eslint-disable-next-line import/no-named-as-default-member
tqI18N
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'all',
    backend: {
      loadPath: `${getTQHostUrl()}/${localePath}`,
    },
    lng: 'en-US',
    fallbackLng: 'en-US',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

tqI18N.on('languageChanged', (lng) => {
  if (glideGridI18n.language !== lng) {
    glideGridI18n.changeLanguage(lng);
  }
});

export default tqI18N;
