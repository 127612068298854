import { createInstance } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

import mocki18n from './tests/mocki18n';

const quotientI18N = createInstance({
  lng: 'en-US',
  fallbackLng: 'en-US',
  debug: false,
});

quotientI18N.use(resourcesToBackend((language: string) => import(`../public/locales/${language}/translation.json`)));
quotientI18N.init();

export const i18n = process.env.NODE_ENV === 'test' ? mocki18n : quotientI18N;
