import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { forwardRef, Fragment, useEffect } from 'react';

import { Menu, MenuList, MenuItem, Box, MenuGroup, MenuDivider, Icon, Flex } from 'quotient';

import { MenuItem as MenuItemType, DEFAULT_GROUPING, MenuGroupOption } from './types';

type Props = {
  isOpen: boolean;
  menuItems: MenuItemType[];
  closeMenu: () => void;
  // For any other arbitrary props like styles, etc to attach to the menu.
  [key: string]: any;
};

const GROUPING_ORDER: MenuGroupOption[] = [DEFAULT_GROUPING, 'additive', 'destructive', 'freeze', 'bottom'];

export const ContextMenu = forwardRef<HTMLDivElement, Props>(({ isOpen, closeMenu, menuItems, ...rest }, ref) => {
  const handleClick = (action: () => void) => {
    action();
    closeMenu();
  };

  const menuGroupings = menuItems.reduce((store, action) => {
    return {
      ...store,
      [action.grouping || DEFAULT_GROUPING]: [...(store[action.grouping || DEFAULT_GROUPING] || []), action],
    };
  }, {} as { [key: string]: MenuItemType[] });
  const groupOrder = Object.keys(menuGroupings);

  useEffect(() => {
    // Will close the current menu when a right click is detected
    const handleDuplicateMenuClose = (e: MouseEvent) => {
      // 0 is left click, 1 is middle click, 2 is right click
      if (e.button === 2) {
        closeMenu();
      }
    };

    if (isOpen) {
      window.addEventListener('mousedown', (e: MouseEvent) => handleDuplicateMenuClose(e));
    }
    return () => {
      window.removeEventListener('mousedown', (e: MouseEvent) => handleDuplicateMenuClose(e));
    };
  }, [isOpen, closeMenu]);

  const getMenuGroupingElements = () => {
    const menuGroupElements: JSX.Element[] = [];
    let counter = 0;
    GROUPING_ORDER.forEach((orderingType, index) => {
      const menuItemElements: JSX.Element[] = [];
      menuGroupings[orderingType]?.forEach((menuGroupItem) => {
        menuItemElements.push(
          <MenuItem
            _active={{ bgColor: 'primary.100', color: 'primary.600' }}
            _focus={{ bgColor: 'secondaryNeutral.200' }}
            alignItems="baseline"
            border={0}
            borderBottomRadius={menuItems.length - 1 === counter ? 8 : 0}
            // make sure first and last entries have rounded borders to not cut off menu list border-radius
            borderColor="transparent"
            borderTopRadius={counter === 0 ? 8 : 0}
            color={menuGroupItem.grouping === 'destructive' ? 'danger.600' : 'primaryNeutral.900'}
            icon={menuGroupItem.icon || null}
            isDisabled={menuGroupItem.disabled}
            key={menuGroupItem.title}
            pb={2}
            pl={3}
            pr={3}
            pt={2}
            onClick={() => handleClick(menuGroupItem.action)}
          >
            <Flex justifyContent="space-between">
              {menuGroupItem.title}
              {menuGroupItem.subMenu && <Icon icon={faChevronRight} />}
            </Flex>
          </MenuItem>,
        );
        counter += 1;
      });
      menuGroupElements.push(
        <Fragment key={orderingType}>
          <MenuGroup>{menuItemElements}</MenuGroup>
          {groupOrder.length > 1 && index <= groupOrder.length - 1 && menuGroupings[orderingType]?.length ? (
            <MenuDivider />
          ) : null}
        </Fragment>,
      );
    });
    return menuGroupElements;
  };

  return (
    <Box pos="relative">
      <Menu closeOnBlur closeOnSelect isLazy isOpen={isOpen} onClose={closeMenu}>
        <MenuList
          border="1px"
          borderColor="primaryNeutral.400"
          boxShadow="lg"
          py={2}
          ref={ref}
          {...rest}
          data-testid="data-grid-header-context-menu"
        >
          {getMenuGroupingElements()}
        </MenuList>
      </Menu>
    </Box>
  );
});
