// Chakra-ui's IconButton uses the same style config as Button component which is insufficient for our styling needs
// For reference: https://github.com/chakra-ui/chakra-ui/issues/3746
// We choose to use "as" props instead of passing "icon" ReactElement like IconButton
// here to give us the ability to style the Icon like a regular Icon component using theme

import { Button, ComponentMultiStyleConfig, useMultiStyleConfig } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { forwardRef, MouseEventHandler } from 'react';

import { CiqQuotientLink } from 'quotient/Application/CiqQuotientLink';
import { focusStyling } from 'quotient/theme/common';
import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';
import { submitEventOnClick } from 'quotient/utils/events';

import { ButtonStyles } from '../Buttons/Button';
import { QuotientIcon } from '../Icon/Icon';

export type IconButtonProps = {
  _className?: string;
  href?: string;
  icon: IconDefinition;
  ['aria-label']?: string;
  buttonName?: string;
  colorScheme?: 'brand' | 'destructive';
  unmask: boolean;
  isDisabled?: boolean;
  onClick?: MouseEventHandler;
} & CommonCIQProps &
  SelectedStyledSystemProps;

export const IconButtonStyles: ComponentMultiStyleConfig = {
  parts: ['button'],
  baseStyle: {
    button: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '36px',
      height: '36px',
      borderRadius: '4px',
      borderStyle: 'solid',
      borderWidth: 0,
    },
  },
  variants: {
    brand: {
      button: {
        ...ButtonStyles.variants?.ghostBrand,
        color: 'textHighContrast',
        _disabled: {
          color: 'textMediumContrast',
        },
        _focus: focusStyling,
      },
    },
    destructive: {
      button: {
        background: 'transparent',
        color: 'surfaceDestructiveDefault',
        _disabled: {
          color: 'surfaceDestructiveDisabled',
        },
        _hover: {
          background: 'surfaceDestructiveHoverLight',
          _disabled: {
            background: 'transparent',
          },
        },
        _active: {
          background: 'surfaceDestructiveActiveLight',
          color: 'surfaceDestructiveActive',
          _disabled: {
            background: 'transparent',
            color: 'surfaceDestructiveDisabled',
          },
        },
        _focus: focusStyling,
      },
    },
  },
};

export const QuotientIconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const {
    _className,
    href,
    isDisabled,
    buttonName,
    onClick,
    'aria-label': ariaLabel,
    unmask,
    icon,
    colorScheme = 'brand',
    ...rest
  } = props;
  const styles = useMultiStyleConfig('QuotientIconButton', { variant: colorScheme });
  return (
    <Button
      __css={styles.button}
      aria-label={ariaLabel}
      as={href ? CiqQuotientLink : undefined}
      className={_className}
      data-text={unmask && ariaLabel ? ariaLabel : undefined}
      href={href as string}
      isDisabled={isDisabled}
      ref={ref}
      unmask={unmask}
      variant="ghost"
      onClick={submitEventOnClick(buttonName, onClick)}
      {...rest}
    >
      <QuotientIcon icon={icon} />
    </Button>
  );
});
