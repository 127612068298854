import { faGrip } from '@fortawesome/pro-regular-svg-icons/faGrip';
import React, { useCallback, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { IBounds, useLayer } from 'react-laag';
import { PlacementType } from 'react-laag/dist/PlacementType';

import { Box, Icon } from 'quotient';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  triggerBoundaries: IBounds;
  enableDrag?: boolean;
  placement?: PlacementType;
  autoPlacement?: boolean;
  closeOnClickOutside?: boolean;
  dragBoundaries?: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
  onClose: () => void;
  zIndex?: number;
};

export const GridPopover: React.VFC<Props> = ({
  children,
  isOpen,
  triggerBoundaries,
  enableDrag,
  dragBoundaries,
  placement,
  closeOnClickOutside,
  onClose,
  zIndex,
  autoPlacement = true,
}: Props) => {
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>();

  // We're manually handing the 'outside' click handler scenario because react-laag doesn't take into account
  // the trigger bounds when we don't explicity have a trigger anchor element (which is the case here).
  useEffect(() => {
    if (!isOpen || !popoverRef || !triggerBoundaries) {
      return;
    }

    function handleClick(event: MouseEvent) {
      const target = event.target as HTMLElement;
      const clickX = event.clientX;
      const clickY = event.clientY;

      // We use the trigger bounds to see if the current click event falls within the trigger.
      const clickedOnTrigger =
        clickX <= triggerBoundaries.right &&
        clickX >= triggerBoundaries.left &&
        clickY <= triggerBoundaries.bottom &&
        clickY >= triggerBoundaries.top;

      // Check if the current click is on the popover itself.
      const clickedOnLayer = popoverRef?.contains(target);

      if (!clickedOnLayer && !clickedOnTrigger && closeOnClickOutside) {
        // We have clicked outside the popover so close it.
        onClose();
      }
    }

    document.addEventListener('click', handleClick, true);
    // eslint-disable-next-line consistent-return
    return () => document.removeEventListener('click', handleClick, true);
  }, [closeOnClickOutside, isOpen, onClose, popoverRef, triggerBoundaries]);

  const { layerProps } = useLayer({
    isOpen: isOpen && !!triggerBoundaries,
    triggerOffset: 4, // keep some distance to the trigger
    auto: autoPlacement, // automatically find the best placement
    placement: placement || 'top-start',
    trigger: triggerBoundaries
      ? {
          getBounds: () => triggerBoundaries,
        }
      : undefined,
  });

  // We dynamically set the bounds of the draggable container based on the current position of the popover
  const getDraggableBounds = useCallback(() => {
    if (dragBoundaries) {
      return dragBoundaries;
    }

    if (popoverRef) {
      // This bounds the popover to be within the grid region
      const gridElem = document.getElementsByClassName('glide-grid')[0];
      const { left, top } = layerProps.style;
      const popoverWidth = popoverRef.getBoundingClientRect().width;
      const popoverHeight = popoverRef.getBoundingClientRect().height;
      const leftBoundary = gridElem.getBoundingClientRect().left - (left as number);
      const rightBoundary = gridElem.getBoundingClientRect().right - ((left as number) + popoverWidth);
      const bottomBoundary = gridElem.getBoundingClientRect().bottom - ((top as number) + popoverHeight);

      return {
        top: (top as number) * -1,
        left: leftBoundary,
        right: rightBoundary,
        bottom: bottomBoundary,
      };
    }

    return undefined;
  }, [dragBoundaries, layerProps.style, popoverRef]);

  return (
    <>
      {isOpen && triggerBoundaries && (
        <Draggable bounds={getDraggableBounds()} disabled={!enableDrag} handle=".drag-icon">
          <Box
            {...layerProps}
            bgColor="primaryNeutral.white"
            borderColor="primaryNeutral.400"
            borderRadius="4px"
            borderStyle="solid"
            borderWidth="1px"
            className="grid-popover"
            overflow="auto"
            px={6}
            py={4}
            ref={(instance: HTMLElement | null) => {
              layerProps.ref(instance);
              setPopoverRef(instance);
            }}
            zIndex={zIndex}
          >
            {enableDrag && (
              <Box position="absolute" right={4}>
                <Icon className="drag-icon" icon={faGrip} />
              </Box>
            )}
            {children}
          </Box>
        </Draggable>
      )}
    </>
  );
};
