import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import {
  Select,
  OptionBase,
  ChakraStylesConfig,
  chakraComponents,
  SelectComponentsConfig as ChakraSelectComponentsConfig,
  GroupBase,
  MultiValue,
  SingleValue,
  InputActionMeta,
  OptionsOrGroups,
} from 'chakra-react-select';
import { FC, FocusEventHandler, ReactNode } from 'react';

import { Text, Button, Icon } from 'quotient';

// https://www.w3.org/WAI/ARIA/apg/patterns/combobox/

export type SelectOption<Meta = {}> = OptionBase & {
  value: string;
  label: ReactNode;
} & Meta;

export type SelectComponentsConfig = ChakraSelectComponentsConfig<SelectOption, boolean, GroupBase<SelectOption>>;

export type ComboboxProps = {
  options: OptionsOrGroups<SelectOption, GroupBase<SelectOption>>;
} & {
  isMulti: boolean;
  value?: MultiValue<SelectOption> | SingleValue<SelectOption>;
  onChange: (newValue: any, actionMeta: any) => void;
  isLoading?: boolean;
  noOptionsMessage?: (obj: { inputValue: string }) => string;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  menuPlacement?: 'auto' | 'top' | 'bottom';
  dataTestId?: string;
  includeDropdownIndicator?: boolean;
  components?: SelectComponentsConfig;
  isClearable?: boolean;
  isDisabled?: boolean;
};
const chakraStyles: ChakraStylesConfig<SelectOption, boolean, GroupBase<SelectOption>> = {
  container: (provided) => ({
    ...provided,
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'borderNeutralPrimaryMediumHighContrast',
    backgroundColor: 'primaryNeutral.white',
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: '4px',
    border: 'none',
    boxShadow: 'none',
    padding: '7px 12px',
    h: 'auto',
  }),
  input: (provided) => ({
    ...provided,
    textStyle: 'body',
    color: 'gray.800',
    padding: '0',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'primary.100' : 'primaryNeutral.white',
    color: 'primaryNeutral.900',
    _hover: {
      backgroundColor: 'secondaryNeutral.200',
    },
    padding: '8px 12px',
  }),
  inputContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontStyle: 'italic',
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: 'md',
    borderRadius: '4px',
    marginTop: '4px',
    borderColor: 'borderNeutralPrimaryMediumHighContrast',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '8px 0px',
    borderRadius: '4px',
    borderColor: 'borderNeutralPrimaryMediumHighContrast',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: 'primaryNeutral.500',
    backgroundColor: 'transparent',
    _hover: {
      color: 'primaryNeutral.900',
      backgroundColor: 'transparent',
    },
    width: 'auto',
    height: 'auto',
  }),
  dropdownIndicator: (provided, { selectProps }) => ({
    ...provided,
    background: 'inherit',
    width: '16px',
    padding: '0',
    svg: {
      transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
      transition: 'transform 0.2s',
    },
  }),
  groupHeading: (provided) => ({
    ...provided,
    padding: '8px 12px',
    border: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
};
const customComponents: SelectComponentsConfig = {
  ClearIndicator: (props: any) => {
    return props.isMulti ? (
      <chakraComponents.ClearIndicator {...props}>
        <Button size="sm" unmask variant="ghost">
          Clear all
        </Button>
      </chakraComponents.ClearIndicator>
    ) : (
      <chakraComponents.ClearIndicator {...props} />
    );
  },
  DropdownIndicator: (props: any) => {
    return (
      <chakraComponents.DropdownIndicator {...props}>
        <Icon color="textHighContrast" icon={faChevronDown} size="xs" />
      </chakraComponents.DropdownIndicator>
    );
  },
  GroupHeading: (props: any) => {
    return (
      <chakraComponents.GroupHeading {...props}>
        <Text color="textMediumContrast" textStyle="capsSubheading">
          {props.children}
        </Text>
      </chakraComponents.GroupHeading>
    );
  },
  Option: (props: any) => {
    return (
      <chakraComponents.Option {...props}>
        <Text textStyle="bodyRegular">{props.children}</Text>
      </chakraComponents.Option>
    );
  },
};

export const QuotientCombobox: FC<ComboboxProps> = ({
  options,
  value,
  onChange,
  onInputChange,
  onBlur,
  isMulti,
  isClearable,
  isDisabled = false,
  dataTestId,
  placeholder,
  noOptionsMessage,
  isLoading,
  components = {},
  includeDropdownIndicator = false,
  menuPlacement = 'bottom',
}) => {
  // modifying chakraStyles makes QuotientCombobox impure, so scoping styles
  // here to prevent impurities. If you need to modify a style dynamically, do it like this.
  const styles = { ...chakraStyles };
  if (includeDropdownIndicator) {
    styles.dropdownIndicator = (provided) => {
      return { ...provided, background: 'none' };
    };
  }

  return (
    <Select
      chakraStyles={styles}
      components={{
        ...customComponents,
        ...components,
      }}
      data-testid={dataTestId}
      hasStickyGroupHeaders
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={Boolean(isMulti)}
      menuPlacement={menuPlacement}
      noOptionsMessage={noOptionsMessage}
      options={options}
      placeholder={placeholder}
      value={value}
      onBlur={onBlur}
      onChange={onChange as any}
      onInputChange={onInputChange}
    />
  );
};
