import { EditableGridCell, GridCell, GridColumn, Item } from '@glideapps/glide-data-grid';
import { MouseEventHandler, ReactElement } from 'react';
import { IBounds } from 'react-laag';
import { PlacementType } from 'react-laag/dist/PlacementType';

import { CellValidatorResult } from './validator-types';

import { MenuItem } from '../components/ContextMenu/types';

export * from '@glideapps/glide-data-grid';
export * from '../components/ContextMenu/types';

// If in the future we want to add custom metadata, we should explore the use of
// generics before looking to add more fields to these types.
// matches the type of EditableGridCell.data
export type CellValue = string | number | boolean | {} | string[] | null | undefined;
export type CellMetadata = { isWarning?: boolean; isError?: boolean; isEdited?: boolean; tooltip?: string };
export type Cell = { value: CellValue; metadata?: CellMetadata };
export type Cells = Record<string, Cell>;
export type RowMetadata = { isNew?: boolean; isDeleted?: boolean };
export type DataRecord = {
  id: string;
  cells: Cells;
  metadata?: RowMetadata;
};

export type DataGridColumn = GridColumn & {
  type: 'text' | 'number' | 'dropdown' | 'boolean' | 'date' | 'badge';
  editable: boolean;
  readonly id: string;
  formula?: string;
  variant: string;
  width: number | undefined;
  format?: NumberFormat | undefined;
  enableCommitMode?: boolean;
  validators?: CellValidatorResult[];
  isRequired?: boolean;
};

export type NumberFormat = {
  comma?: boolean;
  currency_code?: string;
  places?: string;
  type?: string;
};

export type DropdownOption = { value: string; label: string };

/**
 * Options for configuring the async dropdown picker in the Data Grid.
 * @param optionsResolver: callback invoked to get the dropdown options.
 * @param defaultOptions: (optional) Default options list to render
 */
export type DataGridDropdownConfig = {
  asyncOptions: {
    optionsResolver: (
      rowData: Cells,
      columnDefinition: DataGridColumn,
      filterValue: string,
    ) => Promise<DropdownOption[]>;
    defaultOptions?: { value: string; label: string }[];
    getDefaultOptions?: (rowData: Cells, columnDefinition: DataGridColumn) => DropdownOption[];
  };
};

export type DataGridPaginationConfig = {
  paginationPrevLabel: string;
  paginationNextLabel: string;
  pageSize?: number;
  totalPages?: number;
  currentPage: number;
  onChangePage: (newPage: number) => void;
};

export type GridMenuConfig = {
  exportConfig?: GridMenuExportConfig;
  addColumnConfig?: GridMenuAddColumnConfig;
  searchConfig?: GridMenuSearchConfig;
  getCustomConfig?: () => CustomColumnConfig[];
  filterConfig?: GridMenuFilterConfig;
};

export type GridMenuExportConfig = {
  callback: MouseEventHandler<Element>;
};

export type GridMenuAddColumnConfig = {
  callback: MouseEventHandler<Element>;
};
export type GridMenuSearchConfig = {
  onSearch: (value: string) => void;
  searchResults?: Item[];
  searchValue: string;
  searchHelpText?: string;
  searchPlaceholder?: string;
};

export type CustomColumnConfig = {
  menuItem: ReactElement;
};

export type GridPopoverConfig = {
  isOpen: boolean;
  enableDrag?: boolean;
  closeOnClickOutside?: boolean;
  getPopoverContent: () => React.ReactNode;
  onClose: () => void;
  placement?: PlacementType;
  autoPlacement?: boolean;
  zIndex?: number;
};

export type GridMenuFilterConfig = {
  callback: MouseEventHandler<Element>;
  filterCount?: number;
  buttonLabel?: string;
};

export type GridPopoverContext = {
  cell: Item;
  bounds: IBounds;
};

type ErrorMap = {
  [key: string]: string;
};
export const ERROR_VALUE_TO_DISPLAY_VALUE: ErrorMap = {
  'value_error||#VALUE!|Value error': '#VALUE!',
  'value_error||#VALUE!|Invalid data type: None': '#VALUE!',
};

export type PreviousCellData = { column: DataGridColumn; rowId: string; value: CellValue | undefined };

export type CellContentConfigType = {
  postGetCellContent?: (params: {
    cell: Item;
    cellData: CellValue | Record<string, string>;
    columnDefinition: DataGridColumn;
    gridCellContent: GridCell;
  }) => GridCell;
};

export type GridCellUpdate = {
  cell: Item;
  newCellValue: EditableGridCell;
};

export type HeaderContextMenuFrozenColumnConfig = {
  enabled: boolean;
  onFreezeColumn?: (columnIndex: number) => void;
};

export type HeaderContextMenuDeleteColumnConfig = {
  enabled: boolean;
  onDeleteColumn: (columnIndexes: number[]) => void;
};

export type HeaderContextMenuEditColumnConfig = {
  enabled: boolean;
  onEditColumn: (columnIndex: number) => void;
};

export type HeaderContextMenuAddColumnConfig = {
  enabled: boolean;
  onAddColumn: (columnIndexFromEnd: number) => void;
};

export type HeaderContextMenuConfig = {
  editColumnConfig?: HeaderContextMenuEditColumnConfig;
  frozenColumnConfig?: HeaderContextMenuFrozenColumnConfig;
  deleteColumnConfig?: HeaderContextMenuDeleteColumnConfig;
  addLeftColumnConfig?: HeaderContextMenuAddColumnConfig;
  addRightColumnConfig?: HeaderContextMenuAddColumnConfig;
  customItems?: MenuItem[];
};

export type DeleteRowConfig = {
  enabled: boolean;
  onDeleteRow: (rowIds: number[]) => void;
};

export type CellContextMenuItemConfig = {
  deleteRowConfig?: DeleteRowConfig;
  customItems?: MenuItem[];
};

export type badgeMapType = Record<
  string,
  {
    bgColor: string;
    label: string;
    textColor: string;
  }
>;
