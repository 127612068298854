import type { ComponentStyleConfig } from '@chakra-ui/theme';

// note: the component for this currently lives as a component in `frontend`
// will need a separate effort to split that out and isolate as a quotient component if worthy

export const WorksheetTabStyle: ComponentStyleConfig = {
  parts: ['container', 'text', 'textContainer', 'link'],
  baseStyle: {
    container: {
      border: '1px solid',
      left: 4,
      top: '2px',
      borderColor: 'primaryNeutral.400',
      maxWidth: '500px',
    },

    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginRight: 2,
    },

    textContainer: {
      overflow: 'hidden',
      paddingBottom: 1,
      paddingLeft: 3,
      paddingRight: 3,
      paddingTop: 1,
      color: 'primaryNeutral.900',
      maxWidth: '260px',
    },
  },

  variants: {
    active: {
      container: {
        borderBottom: 0,
        backgroundColor: 'primaryNeutral.white',
        h: '36px',
      },

      text: {
        textStyle: 'bodySemiBold',
      },
    },

    'active-grouped-tab': (props) => ({
      container: {
        border: '2px solid',
        borderBottom: 'none',
        backgroundColor: 'primaryNeutral.white',
        borderRadius: '4px 4px 0px 0px',
        left: 0,
        borderColor: props.groupColor ? props.groupColor : 'primaryNeutral.400',
        height: '42px',
      },

      text: {
        textStyle: 'bodySemiBold',
        color: 'primaryNeutral.900',
      },
    }),

    'active-neutral': {
      container: {
        borderBottom: 0,
        backgroundColor: 'primaryNeutral.white',
        h: '36px',
      },

      text: {
        textStyle: 'bodySemiBold',
        color: 'primaryNeutral.900',
      },
    },

    'active-single-tab': {
      container: {
        borderWidth: '2px 2px 0px 2px',
        borderRadius: '4px 4px 0px 0px',
        backgroundColor: 'primaryNeutral.white',
        h: '36px',
      },

      text: {
        fontFamily: 'Inter, Sans-Serif',
        color: 'primaryNeutral.900',
        lineHeight: '26px',
      },

      textContainer: {
        padding: '6px 14px 8px 14px',
      },
    },

    inactive: {
      container: {
        backgroundColor: 'secondaryNeutral.50',
        h: '35px',
      },

      text: {
        textStyle: 'bodyRegular',
        marginRight: '10px',
      },
    },
    'inactive-neutral': {
      container: {
        backgroundColor: 'secondaryNeutral.50',
        h: '35px',
      },

      text: {
        textStyle: 'bodyRegular',
        color: 'primaryNeutral.900',
      },
    },

    'inactive-grouped-tab': (props) => ({
      container: {
        border: 'none',
        left: 0,
        borderBottom: props.groupColor ? '2px solid' : 'none',
        borderBottomColor: props.groupColor ? props.groupColor : undefined,
        height: props.groupColor ? '42px' : '35px',

        _after: {
          content: '""',
          position: 'absolute',
          borderRightWidth: '2px',
          height: '80%',
          width: '100%',
          borderRight: !props.showDivider ? '1px solid' : 'none',
          borderRightColor: 'primaryNeutral.400',
        },
      },

      text: {
        textStyle: 'bodyRegular',
        color: 'primaryNeutral.900',
      },
    }),
  },

  defaultProps: {
    variant: 'active',
  },
};
