import { faArchive } from '@fortawesome/pro-regular-svg-icons/faArchive';
import { faMessage } from '@fortawesome/pro-regular-svg-icons/faMessage';
import { faThumbsDown } from '@fortawesome/pro-regular-svg-icons/faThumbsDown';
import { faThumbsUp } from '@fortawesome/pro-regular-svg-icons/faThumbsUp';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Text, Avatar, Button, Collapse, Icon } from 'quotient';
import { colors } from 'quotient/theme/foundations/colors/colors';

import { Comment, EMPTY_COMMENT_VALUE, ThreadComments } from './ThreadBoxComments';
import { ThreadInputField } from './ThreadInputField';

export type Author = {
  name: string;
  id: string;
  avatarURL?: string;
};

type ThreadContainerProps = {
  author: Author;
  timestamp?: string;
  content?: string;
  currentUser?: Author;
  hideComments?: boolean;
  disableComments?: boolean;
  alert?: JSX.Element;
  comments?: Comment[];
  hideCommentsSummary?: boolean;
  hideActionBar?: boolean;
  enableArchive?: boolean;
  enableProposedChanges?: boolean;
  width?: string | number;
  height?: string | number;
  commentsMaxHeight?: string | number;
  onContentSaved?: (newComment: Comment) => void;
  onCommentSaved?: (newComment: Comment) => void;
  onCommentCancel?: () => void;
  onArchive?: () => void;
  onProposedChangeAccepted?: () => void;
  onProposedChangeRejected?: () => void;
  extraContent?: JSX.Element[] | JSX.Element | string;
  initialCommentText?: string;
  isSubmitDisabled?: boolean;
  disableAutoFocus?: boolean;
  hideContentInput?: boolean;
  cancelButtonText?: string;
  closedReplyDrawerLabel?: string;
  openReplyDrawerLabel?: string;
  replyText?: string;
  replyCTA?: string;
};

const buttonIconStyles = `
#thread-container .chakra-button__icon {
    margin-inline-end: 2px;
}`;

export const ThreadContainer: React.VFC<ThreadContainerProps> = ({
  author,
  currentUser,
  content,
  timestamp,
  comments,
  alert,
  hideComments,
  hideCommentsSummary,
  disableComments,
  hideActionBar,
  enableArchive,
  enableProposedChanges,
  width,
  height,
  commentsMaxHeight,
  onContentSaved,
  onCommentSaved,
  onCommentCancel,
  onArchive,
  onProposedChangeAccepted,
  onProposedChangeRejected,
  extraContent,
  initialCommentText,
  isSubmitDisabled,
  disableAutoFocus,
  hideContentInput,
}) => {
  const { t } = useTranslation(['quotient']);
  const [commentsDisplayed, setCommentsDisplayed] = useState<boolean>(true);
  const [displayReplyInput, setDisplayReplyInput] = useState<boolean>(false);

  const onNewCommentSaved = (newComment: string, isNewContent = false) => {
    if (isNewContent && onContentSaved) {
      onContentSaved({
        author: currentUser as Author,
        content: newComment,
        timestamp: new Date().toISOString(),
      });
    }
    if (!isNewContent && onCommentSaved) {
      onCommentSaved({
        author: currentUser as Author,
        content: newComment,
        timestamp: new Date().toISOString(),
      });
    }
    setDisplayReplyInput(false);
  };

  const onArchiveHandler = () => {
    if (onArchive) {
      onArchive();
    }
  };

  const shouldDisplayFooterSection = !hideActionBar || (!hideCommentsSummary && comments && comments.length > 0);
  const shouldDisplayCommentsSection =
    (!hideComments && comments && comments.length > 0) || (currentUser && displayReplyInput);

  const sanitizedTimestamp = timestamp ? new Date(timestamp) : new Date();

  const shouldHideContentInput = hideContentInput || !onContentSaved;

  return (
    <>
      <style>{buttonIconStyles}</style>
      <Box data-testid="thread-container" height={height} id="thread-container" width={width}>
        <Box>
          <Flex direction="row">
            <Avatar name={author.name} size="lg" src={author?.avatarURL || ''} />
            <Box marginLeft={2.5}>
              <Text margin={0} textStyle="bodySemiBold">
                {author.name}
              </Text>
              <Text color="primaryNeutral.700" fontSize="12px" margin={0}>
                {moment(sanitizedTimestamp).format('D MMM YYYY, h:mm a')}
              </Text>
            </Box>
            {enableProposedChanges && (
              <Flex ml="auto" mr="25px">
                <Button
                  isDisabled={isSubmitDisabled}
                  leftIcon={faThumbsUp}
                  size="sm"
                  unmask
                  variant="ghost"
                  onClick={onProposedChangeAccepted ? () => onProposedChangeAccepted() : undefined}
                >
                  Accept
                </Button>
                <Button
                  colorScheme="destructive"
                  isDisabled={isSubmitDisabled}
                  leftIcon={faThumbsDown}
                  size="sm"
                  unmask
                  variant="ghost"
                  onClick={onProposedChangeRejected ? () => onProposedChangeRejected() : undefined}
                >
                  Reject
                </Button>
              </Flex>
            )}
            {enableArchive && !enableProposedChanges && (
              <Button
                alignSelf="start"
                leftIcon={faArchive}
                ml="auto"
                mr="25px"
                size="sm"
                unmask
                variant="ghost"
                onClick={onArchiveHandler}
              >
                Archive
              </Button>
            )}
          </Flex>
          {extraContent && <Box>{extraContent}</Box>}
          {!content && !shouldHideContentInput && (
            <Box>
              <ThreadInputField
                alert={alert}
                currentUser={currentUser as Author}
                disableAutoFocus={disableAutoFocus}
                hideCommentHeader
                initialComment
                initialCommentText={initialCommentText}
                isSubmitDisabled={isSubmitDisabled}
                onCancel={() => {
                  if (onCommentCancel) {
                    onCommentCancel();
                  }
                }}
                onSave={(newComment) => onNewCommentSaved(newComment, true)}
              />
            </Box>
          )}
          {content && content !== EMPTY_COMMENT_VALUE && (
            <Flex direction="row" marginTop="1em" whiteSpace="pre-wrap">
              {content}
            </Flex>
          )}
          {shouldDisplayFooterSection && content && (
            <Flex alignItems="center" direction="row" marginTop="1em">
              {!hideCommentsSummary && comments && comments.length > 0 && (
                <>
                  <Icon color="primaryNeutral.700" icon={faMessage} mr="8px" />
                  <Text color="primaryNeutral.700" margin={0}>
                    {comments.length} {t('worksheet_collaboration.thread.comment', { count: comments.length })}
                  </Text>
                </>
              )}
              {!hideActionBar && (
                <Flex>
                  <Button
                    isDisabled={disableComments}
                    leftIcon={faMessage}
                    size="sm"
                    unmask
                    variant="ghost"
                    onClick={() => setDisplayReplyInput(true)}
                  >
                    {t('worksheet_collaboration.thread.reply', { count: 1 })}
                  </Button>
                  {comments && comments.length > 0 && (
                    <Button size="sm" unmask variant="ghost" onClick={() => setCommentsDisplayed(!commentsDisplayed)}>
                      {commentsDisplayed ? t('global.actions.collapse') : t('global.actions.expand')} {comments.length}{' '}
                      {t('worksheet_collaboration.thread.reply', { count: comments.length })}
                    </Button>
                  )}
                </Flex>
              )}
            </Flex>
          )}
        </Box>
        {shouldDisplayCommentsSection && (
          <Box
            borderTop={`1px solid ${colors.primaryNeutral[400]}`}
            marginTop="1em"
            maxHeight={commentsMaxHeight}
            overflowY="auto"
          >
            {comments && comments.length > 0 && (
              <Collapse animateOpacity in={commentsDisplayed}>
                <ThreadComments comments={comments} />
              </Collapse>
            )}
            {displayReplyInput && currentUser && (
              <Box marginLeft="2em" marginTop="0.5em" pr="1em">
                <ThreadInputField
                  alert={alert}
                  currentUser={currentUser}
                  onCancel={() => setDisplayReplyInput(false)}
                  onSave={onNewCommentSaved}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
