// eslint-disable-next-line import/no-named-as-default
import { createInstance } from 'i18next';

import enUS from '../../public/locales/en-US/translation.json';

const mockQuotientI18N = createInstance();
// eslint-disable-next-line import/no-named-as-default-member
mockQuotientI18N.init({
  // hard coding tests to just english for now
  lng: 'en-US',
  fallbackLng: 'en-US',
  resources: {
    'en-US': {
      translation: enUS,
    },
    en: {
      translation: enUS,
    },
  },
});

export default mockQuotientI18N;
