import { useQueryClient, useQuery, useMutation, UseMutationOptions, UseQueryOptions } from 'react-query';

import { deleteRequest, getRequest, postRequest, putRequest } from 'services/apiRequests';
import type { APIList, StringifiedUUID } from 'types';
import { SCPModel, SCPModelRequest, SCPProposal } from 'types/scpModels';
import type { APIError } from 'utils/errors';

const apiUrl = '/api/v1/spm/scp-models/';

const getSCPModel = (scpModelId: StringifiedUUID) => getRequest<null, SCPModel>(`${apiUrl}${scpModelId}/`);

const useGetSCPModelQuery = (scpModelId: StringifiedUUID) => {
  const QUERY_KEY = ['scp-models', scpModelId];
  const queryClient = useQueryClient();

  const query = useQuery<SCPModel, APIError>(QUERY_KEY, () => getSCPModel(scpModelId));
  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);

  return { query, invalidateQuery };
};

const listSCPModels = () => getRequest<null, APIList<SCPModel>>(apiUrl);

const useListSCPModelsQuery = (options: UseQueryOptions<APIList<SCPModel>, APIError, SCPModel[]> = {}) => {
  const QUERY_KEY = ['scp-models', 'list'];
  const queryClient = useQueryClient();
  const query = useQuery<APIList<SCPModel>, APIError, SCPModel[]>(QUERY_KEY, () => listSCPModels(), {
    select: (response) => response.data || [],
    ...options,
  });
  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);

  return { query, invalidateQuery };
};

const createSCPModel = (csrfToken: string) => (scpModel: SCPModelRequest) => {
  return postRequest<SCPModelRequest, SCPModel>(apiUrl, scpModel, csrfToken);
};

const useCreateSCPModelMutation = (
  csrfToken: string,
  options: UseMutationOptions<SCPModel, APIError, SCPModelRequest> = {},
) => {
  return {
    mutation: useMutation<SCPModel, APIError, SCPModelRequest>(createSCPModel(csrfToken), options),
  };
};

type UpdateSCPModelProps = {
  scpModelId: StringifiedUUID;
  scpModel: SCPModelRequest;
};

const updateSCPModel = (csrfToken: string) => ({ scpModelId, scpModel }: UpdateSCPModelProps) => {
  return putRequest<SCPModelRequest, SCPModel>(`${apiUrl}${scpModelId}/`, scpModel, csrfToken);
};

const useUpdateSCPModelMutation = (
  csrfToken: string,
  options: UseMutationOptions<SCPModel, APIError, UpdateSCPModelProps> = {},
) => {
  return {
    mutation: useMutation<SCPModel, APIError, UpdateSCPModelProps>(updateSCPModel(csrfToken), options),
  };
};

const deleteSCPModel = (csrfToken: string) => (scpModelId: StringifiedUUID) => {
  return deleteRequest<null, void>(`${apiUrl}${scpModelId}/`, null, csrfToken);
};

const useDeleteSCPModelMutation = (
  csrfToken: string,
  options: UseMutationOptions<void, APIError, StringifiedUUID> = {},
) => {
  return {
    mutation: useMutation<void, APIError, StringifiedUUID>(deleteSCPModel(csrfToken), options),
  };
};

/**
 * Get intelligence suggestions for a specific SCP model and scenario
 */
const getModelIntelligence = (scpModelId: StringifiedUUID, scenarioId: StringifiedUUID) =>
  getRequest<null, APIList<SCPProposal>>(
    `/api/v1/spm/scp-models/${scpModelId}/intelligence/?scenario_uuid=${scenarioId}`,
  );

/**
 * Hook to fetch intelligence data for an SCP model and scenario
 */
const useModelIntelligenceQuery = (scpModelId: StringifiedUUID, scenarioId: StringifiedUUID) => {
  const QUERY_KEY = ['scp-intelligence', scpModelId, scenarioId];

  const query = useQuery<APIList<SCPProposal>, APIError, SCPProposal[]>(
    QUERY_KEY,
    () => getModelIntelligence(scpModelId, scenarioId),
    {
      enabled: !!scpModelId && !!scenarioId,
      select: (response) => response.data || [],
    },
  );

  return { query };
};

export const SCPModelsService = {
  apiUrl,
  useGetSCPModelQuery,
  useListSCPModelsQuery,
  useCreateSCPModelMutation,
  useUpdateSCPModelMutation,
  useDeleteSCPModelMutation,
  useModelIntelligenceQuery,
};
