import { Avatar as ChakraAvatar } from '@chakra-ui/react';

import { ComponentStyleConfig } from 'quotient';
import { SelectedStyledSystemProps } from 'quotient/types';

export const AvatarStyle: ComponentStyleConfig = {
  baseStyle: {
    container: {
      // replace this with surfaceNavigationDefault once dark mode is enabled app wide
      bg: 'surfaceNavigationDefaultNavOnly',
      borderRadius: '36px',
      borderWidth: '1px',
      // replace this with borderNeutralPrimaryMediumContrast once dark mode is enabled app wide
      borderColor: 'borderNeutralPrimaryMediumContrastNavOnly',
    },
  },
  sizes: {
    sm: {
      container: {
        w: '24px',
        h: '24px',
      },
      label: {
        fontSize: '12px',
      },
    },
    lg: {
      container: {
        w: '36px',
        h: '36px',
      },
      label: {
        fontSize: '16px',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
};

export type QuotientAvatarProps = {
  name: string;
  size: 'lg' | 'sm';
  src?: string;
  icon?: JSX.Element;
} & SelectedStyledSystemProps;

export const QuotientAvatar = ({ name, size, src, ...props }: QuotientAvatarProps) => {
  // replace this with textHighContrast once dark mode is enabled app wide
  return <ChakraAvatar color="textHighContrastNavOnly" name={name} showBorder size={size} src={src} {...props} />;
};
