import {
  GridSelection,
  Item,
  EditableGridCell,
  CellClickedEventArgs,
  NumberCell,
  GridCell,
  GridCellKind,
} from '@glideapps/glide-data-grid';
import { IBounds } from 'react-laag';

import { DropdownCell } from './components/DropdownCell';
import { NumberFormatter } from './formatters/NumberFormatter';
import { Cells, DataGridColumn, DataGridDropdownConfig } from './types/grid-types';

const copyCellWithNewData: (
  xCoords: number,
  yCoords: number,
  doesCellExist: Function,
  getCellContent: Function,
  newValue: any,
  dropdownOptionsMapper?: (cells: Cells, columnId: string) => { label: string; value: string }[],
  dropdownConfig?: DataGridDropdownConfig,
) => any = (xCoords, yCoords, doesCellExist, getCellContent, newValue, dropdownOptionsMapper, dropdownConfig) => {
  const cellExists = doesCellExist([xCoords, yCoords]);
  if (!cellExists) {
    return null;
  }
  const cellCopy = getCellContent([xCoords, yCoords], dropdownOptionsMapper, dropdownConfig) as any;
  if (!cellCopy.allowOverlay) {
    // This is not an editable cell, so don't copy into it
    return null;
  }
  if (cellCopy.data?.kind === 'dropdown-cell') {
    return {
      location: [xCoords, yCoords],
      value: {
        ...cellCopy,
        data: {
          ...cellCopy.data,
          label: newValue,
          value: newValue,
        },
      },
    };
  }
  return {
    location: [xCoords, yCoords],
    value: {
      ...cellCopy,
      data: newValue,
      displayData: newValue,
    },
  };
};

export const onPasteWithSingleRowTiling: (
  target: Item,
  values: readonly (readonly string[])[],
  selection: GridSelection,
  doesCellExist: Function,
  getCellContent: Function,
  onCellsEdited: Function,
  onCellsDataChanged?: (cellUpdates: { cell: Item; newCellValue: EditableGridCell }[]) => void,
  dropdownOptionsMapper?: (cells: Cells, columnId: string) => { label: string; value: string }[],
  dropdownConfig?: DataGridDropdownConfig,
) => boolean = (
  target,
  values,
  selection,
  doesCellExist,
  getCellContent,
  onCellsEdited,
  onCellsDataChanged,
  dropdownOptionsMapper,
  dropdownConfig,
) => {
  const [col, row] = target;
  const { current } = selection;
  if (current) {
    const { range } = current;
    if (values.length === 1 && range.height > 1) {
      // hacky, only override if SINGLE row selection in the future we want full excel capability
      // that extends copy and paste buffer to the entire selection repeating tile
      // otherwise, do the default paste behavior
      const rowData = values[0];
      const newValues = [];
      for (let i = 0; i < rowData.length; i += 1) {
        for (let j = 0; j < range.height; j += 1) {
          const cellCopy = copyCellWithNewData(
            col + i,
            row + j,
            doesCellExist,
            getCellContent,
            rowData[i],
            dropdownOptionsMapper,
            dropdownConfig,
          );
          if (cellCopy) {
            newValues.push(cellCopy);
          }
        }
      }

      onCellsEdited(newValues as any, onCellsDataChanged);
      return false;
    }
    const newValues = [];
    for (let valueRow = 0; valueRow < values.length; valueRow++) {
      let value = values[valueRow];
      if (value.length === 0) {
        // when only one empty cell is copied, it should be pasted as an empty cell
        value = [''];
      } else if (value[value.length - 1] === '') {
        // Glide's default copy does not include the last empty cell, so we need to add it back
        value = [...value, ''];
      }
      for (let valueColumn = 0; valueColumn < value.length; valueColumn++) {
        const cellCopy = copyCellWithNewData(
          col + valueColumn,
          row + valueRow,
          doesCellExist,
          getCellContent,
          value[valueColumn],
          dropdownOptionsMapper,
          dropdownConfig,
        );
        if (cellCopy) {
          newValues.push(cellCopy);
        }
      }
    }

    onCellsEdited(newValues as any, onCellsDataChanged);
    return false;
  }
  return true;
};

export const onCellClickHandler: (
  cell: Item,
  event: CellClickedEventArgs,
  setCellPopoverContext: Function,
  trackCellClickPositions?: boolean,
  onCellClicked?: (cell: Item, bounds: IBounds) => void,
) => void = (cell, event, setCellPopoverContext, trackCellClickPositions, onCellClicked) => {
  const cellClickContext = {
    cell,
    bounds: {
      // translate to react-laag types
      left: event.bounds.x,
      top: event.bounds.y,
      width: event.bounds.width,
      height: event.bounds.height,
      right: event.bounds.x + event.bounds.width,
      bottom: event.bounds.y + event.bounds.height,
    },
  };

  if (trackCellClickPositions) {
    setCellPopoverContext(cellClickContext);
  }

  if (onCellClicked) {
    onCellClicked(cellClickContext.cell, cellClickContext.bounds);
  }
};

export const getCellValue = (newCellValue: EditableGridCell, columnConfig: DataGridColumn) => {
  // Numbers
  if (columnConfig.type === 'number') {
    return NumberFormatter.getValue(newCellValue.data as string, columnConfig.format);
  }

  // Default for string columns
  return newCellValue.data;
};

/**
 * Used to coerce the paste value before applying it to the target cell. This is essentially a 'pre' hook that
 * allows us to modify the paste value before applying it.
 * @param val The value to paste
 * @param cell The target cell
 * @returns GridCell or undefined
 */
export const coercePasteValue = (val: string, cell: GridCell) => {
  // For number cells we handle pasting of formatted values. We essentially extract the raw value
  // from the formatted one and apply that instead.
  if (cell.kind === GridCellKind.Number) {
    const rawValue = NumberFormatter.getValue(val);
    const cellCopy: NumberCell = {
      ...cell,
      data: Number(rawValue),
      displayData: rawValue,
    };
    return cellCopy;
  }
  return undefined;
};

export const isDropdownCell = (cell: EditableGridCell): cell is DropdownCell => {
  return cell.kind === 'custom' && (cell as DropdownCell).data.kind === 'dropdown-cell';
};
