import faker from 'faker';
import { Factory } from 'fishery';

import { DataGridColumn, DataRecord, Cells, CellValue } from '../../types/grid-types';

export const column = Factory.define<DataGridColumn>(({ params }) => ({
  title: faker.name.findName(),
  id: faker.datatype.uuid(),
  type: params.type || 'text',
  editable: params.editable || false,
  variant: 'raw',
  format: params.format || undefined,
  width: 75,
}));

type DataRecordFactory = DataRecord & {
  columnIds?: string[];
  customDataValues?: Cells;
};

export const dataRecord = Factory.define<DataRecordFactory>(({ params }) => {
  const data: Cells = {};
  if (params?.columnIds) {
    params.columnIds.forEach((id) => {
      const cellValue: CellValue = params?.customDataValues
        ? params?.customDataValues[id as string] || faker.name.findName()
        : faker.name.findName();

      data[id as string] = { value: cellValue };
    });
  }
  return {
    id: faker.datatype.uuid(),
    cells: data,
  };
});
