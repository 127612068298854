import { useQueryClient, useQuery, useMutation, UseMutationOptions, UseQueryOptions } from 'react-query';

import { getRequest, postRequest } from 'services/apiRequests';
import type { StringifiedUUID } from 'types';
import { SCPRecordsResponse } from 'types/scpModels';
import type { APIError } from 'utils/errors';

const baseApiUrl = '/api/v1/spm/scp-models/';

// Construct full API URL for SCPRecords
const getSCPRecordsUrl = (scpModelId: StringifiedUUID, scenarioId: StringifiedUUID, worksheetId: StringifiedUUID) =>
  `${baseApiUrl}${scpModelId}/scenarios/${scenarioId}/worksheets/${worksheetId}/records/`;

// Get records for a worksheet
const getSCPRecords = (scpModelId: StringifiedUUID, scenarioId: StringifiedUUID, worksheetId: StringifiedUUID) => {
  const url = getSCPRecordsUrl(scpModelId, scenarioId, worksheetId);
  return getRequest<null, SCPRecordsResponse>(url);
};

const useGetSCPRecordsQuery = (
  scpModelId: StringifiedUUID | undefined,
  scenarioId: StringifiedUUID | undefined,
  worksheetId: StringifiedUUID | undefined,
  options: UseQueryOptions<SCPRecordsResponse, APIError> = {},
) => {
  const QUERY_KEY = ['scp-models', scpModelId, 'scenarios', scenarioId, 'worksheets', worksheetId, 'records'];
  const queryClient = useQueryClient();

  const query = useQuery<SCPRecordsResponse, APIError>(
    QUERY_KEY,
    () => {
      // Only call if all parameters are defined
      if (!scpModelId || !scenarioId || !worksheetId) {
        throw new Error('Required parameters missing');
      }
      return getSCPRecords(scpModelId, scenarioId, worksheetId);
    },
    {
      // Only run this query if all IDs are defined
      enabled: !!scpModelId && !!scenarioId && !!worksheetId,
      ...options,
    },
  );

  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);

  // Invalidate all worksheet records in this model and scenario
  const invalidateAllWorksheetQueries = () => {
    if (scpModelId && scenarioId) {
      queryClient.invalidateQueries(['scp-models', scpModelId, 'scenarios', scenarioId, 'worksheets']);
    }
  };

  return { query, invalidateQuery, invalidateAllWorksheetQueries };
};

// Update records for a worksheet
export type UpdateSCPRecordsRequest = {
  object: string;
  updates: {
    object: string;
    data: Array<{
      column_id: string;
      time_key: string;
      value: string | null;
    }>;
  };
  deletes: {
    object: string;
    data: Array<any>;
  };
};

export type UpdateSCPRecordsProps = {
  scpModelId: StringifiedUUID;
  scenarioId: StringifiedUUID;
  worksheetId: StringifiedUUID;
  data: UpdateSCPRecordsRequest;
};

const updateSCPRecords = (csrfToken: string) => ({
  scpModelId,
  scenarioId,
  worksheetId,
  data,
}: UpdateSCPRecordsProps) => {
  const url = getSCPRecordsUrl(scpModelId, scenarioId, worksheetId);
  return postRequest<UpdateSCPRecordsRequest, SCPRecordsResponse>(url, data, csrfToken);
};

const useUpdateSCPRecordsMutation = (
  csrfToken: string,
  options: UseMutationOptions<SCPRecordsResponse, APIError, UpdateSCPRecordsProps> = {},
) => {
  return {
    mutation: useMutation<SCPRecordsResponse, APIError, UpdateSCPRecordsProps>(updateSCPRecords(csrfToken), options),
  };
};

export const SCPRecordsService = {
  useGetSCPRecordsQuery,
  useUpdateSCPRecordsMutation,
};
