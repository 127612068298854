import { forwardRef } from '@chakra-ui/react';

import { Field, FieldProps } from '../Field';
import { QuotientInput, QuotientInputProps } from '../Input/Input';

type InputGroupFieldProps = FieldProps & QuotientInputProps & { inputProps?: QuotientInputProps };

export const QuotientInputGroupField = forwardRef<InputGroupFieldProps, 'input'>(
  (
    {
      id,
      label,
      description,
      isRequired,
      showOptional,
      isInvalid,
      errorMessage,
      value,
      placeholder,
      isDisabled,
      leftIcon,
      rightIcon,
      type,
      onChange,
      'data-testid': dataTestId,
      onBlur,
      inputProps,
      ...rest
    }: InputGroupFieldProps,
    ref,
  ) => {
    return (
      <Field
        description={description}
        errorMessage={errorMessage}
        id={id}
        isInvalid={isInvalid}
        isRequired={isRequired}
        label={label}
        showOptional={showOptional}
        {...rest}
      >
        <QuotientInput
          data-testid={dataTestId}
          isDisabled={isDisabled}
          leftIcon={leftIcon}
          placeholder={placeholder}
          ref={ref}
          rightIcon={rightIcon}
          type={type}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          {...inputProps}
        />
      </Field>
    );
  },
);
