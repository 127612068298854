import { useQueryClient, useMutation, UseMutationOptions } from 'react-query';

import { postRequest } from 'services/apiRequests';
import type { StringifiedUUID } from 'types';
import { Scenario } from 'types/scpModels';
import type { APIError } from 'utils/errors';

const apiUrlBase = '/api/v1/spm/scp-models/';

// Interface for the clone scenario request
export interface CloneScenarioRequest extends Record<string, unknown> {
  source_scenario_uuid: StringifiedUUID;
  name?: string;
  description?: string;
  is_system?: boolean;
  intelligence_suggestion?: {
    name: string;
    description: string;
    inputs: Array<{
      column: string;
      time_key: string;
      value: number;
    }>;
  };
}

/**
 * Clone an existing scenario
 * @param csrfToken CSRF token
 * @param scpModelId SCP model ID
 * @returns Function to clone a scenario
 */
const cloneScenario = (csrfToken: string, scpModelId: StringifiedUUID) => (request: CloneScenarioRequest) => {
  const apiUrl = `${apiUrlBase}${scpModelId}/scenarios/clone/`;
  return postRequest<CloneScenarioRequest, Scenario>(apiUrl, request, csrfToken);
};

/**
 * Hook to use the clone scenario mutation
 * @param csrfToken CSRF token
 * @param scpModelId SCP model ID
 * @param options Mutation options
 * @returns Mutation for cloning a scenario
 */
const useCloneScenarioMutation = (
  csrfToken: string,
  scpModelId: StringifiedUUID,
  options: UseMutationOptions<Scenario, APIError, CloneScenarioRequest> = {},
) => {
  const queryClient = useQueryClient();

  return {
    mutation: useMutation<Scenario, APIError, CloneScenarioRequest>(cloneScenario(csrfToken, scpModelId), {
      ...options,
      onSuccess: (data, variables, context) => {
        // Invalidate the SCP model query to refresh the scenarios list
        queryClient.invalidateQueries(['scp-models', scpModelId]);

        // Call the original onSuccess if provided
        if (options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }),
  };
};

export const SCPScenariosService = {
  useCloneScenarioMutation,
};
