import { RadioGroup as ChakraRadioGroup, Radio as ChakraRadio, Flex, Text } from '@chakra-ui/react';
import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';

import {
  focusStyling,
  labelStyling,
  inputDefaultStyling,
  inputDisabledStyling,
  inputCheckboxDisabledStyling,
  inputCheckedStyling,
} from 'quotient/theme/common';
import { QuotientBadge, BadgeVariant } from 'quotient/theme/components/Badge/Badge';
import { QuotientIcon } from 'quotient/theme/components/Icon/Icon';
import { QuotientTooltip } from 'quotient/theme/components/Tooltip/Tooltip';
import { fontSizes } from 'quotient/theme/foundations/typography/typography';
import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';

export const RadioStyle: ComponentStyleConfig = {
  baseStyle: {
    control: {
      ...inputDefaultStyling,
      _checked: {
        ...inputCheckedStyling,
        _before: {
          w: '45%',
          h: '45%',
        },
        _hover: inputCheckedStyling,
        _disabled: inputCheckboxDisabledStyling,
      },
      _disabled: inputDisabledStyling,
      _focus: focusStyling,
    },
    container: {
      m: 0,
    },
    label: {
      ...labelStyling,
      marginLeft: '8px',
    },
  },
  sizes: {
    md: {
      control: {
        w: '20px',
        h: '20px',
      },
      label: {
        fontSize: fontSizes.sm,
      },
    },
  },
};

type RadioValue = string | number | undefined;

type RadioItem = {
  label: string;
  value: RadioValue;
  helperText?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  tooltip?: string;
  badgeVariant?: BadgeVariant;
};

export type RadioGroupProps = {
  _className?: string;
  items: RadioItem[];
  onChange: (value: string) => void;
  value: RadioValue;
  name?: string;
  isDisabled?: boolean;
  direction?: 'row' | 'column';
  badgeVariant?: string;
} & SelectedStyledSystemProps &
  CommonCIQProps;

export const QuotientRadioGroup = ({
  _className,
  items,
  onChange,
  value,
  name,
  isDisabled,
  direction = 'column',
  ...rest
}: RadioGroupProps) => {
  return (
    <ChakraRadioGroup
      className={_className}
      isDisabled={isDisabled}
      name={name}
      value={value}
      onChange={onChange}
      {...rest}
    >
      <Flex direction={direction} gap="16px">
        {items.map((item) => (
          <Flex direction="column" key={`${item.value}-container`}>
            <ChakraRadio
              alignItems="center"
              isDisabled={item.isDisabled}
              isRequired={item.isRequired}
              key={item.value}
              value={item.value}
            >
              {item.badgeVariant ? (
                <QuotientTooltip id="column_data_id" label={item.tooltip} placement="top">
                  <QuotientBadge variant={item.badgeVariant}>
                    <Flex alignItems="center" gap={1}>
                      <Text textStyle="smallRegular">{item.label}</Text>{' '}
                      {item.tooltip && <QuotientIcon icon={faCircleQuestion} />}
                    </Flex>
                  </QuotientBadge>
                </QuotientTooltip>
              ) : (
                item.label
              )}
            </ChakraRadio>
            {item.helperText && (
              <Text color="primaryNeutral.700" ml={7} textStyle="bodyRegular">
                {item.helperText}
              </Text>
            )}
          </Flex>
        ))}
      </Flex>
    </ChakraRadioGroup>
  );
};
