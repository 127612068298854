import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { Action, useKBar, useRegisterActions } from 'kbar';
import { DependencyList } from 'react';

import { IconButton } from 'quotient';

type Props = {
  actions?: Action[];
  dependencies?: DependencyList;
  rootId?: string;
  onClickCallback?: () => void;
};

export const CommandBarButton = ({ actions, dependencies, rootId, onClickCallback }: Props) => {
  const { query } = useKBar();

  useRegisterActions(actions || [], dependencies || []);

  return (
    <IconButton
      aria-label="Command bar button"
      colorScheme="brand"
      icon={faChevronDown}
      unmask
      onClick={() => {
        query.toggle();
        if (rootId) {
          query.setCurrentRootAction(rootId);
        }
        if (onClickCallback) {
          onClickCallback();
        }
      }}
    />
  );
};
